<template>
    <div
        v-if="items && items.length"
        class="slider"
        :class="{
            'drag-to-scroll--disabled': items.length <= 1
        }"
    >
        <BaseSliderDragToScroll
            ref="scrollContainer"
            class="slider__track"
        >
            <slot name="default">
                <div
                    v-if="hasPadding"
                    class="slider__padding"
                />

                <div
                    v-for="(item, index) in items"
                    :key="`slide_${index}`"
                    :ref="(el) => (itemsEl[index] = el)"
                    class="slider__item"
                    @enter="itemIsVisible[index] = true"
                    @leave="itemIsVisible[index] = false"
                >
                    <slot
                        name="slide"
                        :item="item"
                        :index="index"
                        :is-visible="itemIsVisible[index]"
                    />
                </div>

                <div
                    v-if="hasPadding"
                    class="slider__padding"
                />
            </slot>
        </BaseSliderDragToScroll>

        <div
            v-if="items?.length > 1"
            class="slider__controls"
            :class="{
                'slider__controls--scroll': paginationType === 'scroll'
            }"
        >
            <div class="slider__controls--content">
                <slot
                    v-if="controlsEnabled"
                    name="controlLeft"
                    :scroll-left="scrollLeft"
                >
                    <button
                        data-left
                        :disabled="!overflowLeft"
                        class="slider__control"
                        :class="{
                            'slider__control--disabled': !overflowLeft
                        }"
                        type="button"
                        @click.prevent="scrollLeft"
                    >
                        <slot name="controlLeftLabel">Left</slot>
                    </button>
                </slot>

                <slot
                    v-if="paginationEnabled"
                    name="pagination"
                    :items="items"
                    :item-is-visible="itemIsVisible"
                >
                    <div
                        v-if="paginationType === 'scroll'"
                        class="slider__pagination"
                    >
                        <div>Scroll</div>
                    </div>
                    <div
                        v-else
                        class="slider__pagination"
                    >
                        <button
                            class="slider__pagination--current"
                            :disabled="!overflowLeft"
                            @click="scrollLeft"
                        >
                            {{ currentIndex + 1 }}
                        </button>
                        <div> - </div>
                        <button
                            class="slider__pagination--total"
                            :disabled="!overflowRight"
                            @click="scrollRight"
                        >
                            {{ items.length }}
                        </button>
                    </div>
                </slot>

                <slot
                    v-if="controlsEnabled"
                    name="controlRight"
                    :scroll-right="scrollRight"
                    :is-visible="overflowRight"
                >
                    <button
                        data-right
                        class="slider__control"
                        :class="{
                            'slider__control--disabled': !overflowRight
                        }"
                        type="button"
                        :disabled="!overflowRight"
                        @click.prevent="scrollRight"
                    >
                        <slot name="controlRightLabel">Right</slot>
                    </button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick, watch, onMounted, onUnmounted } from 'vue';
import BaseSliderDragToScroll from '~/components/BaseSliderDragToScroll.vue';

const props = defineProps({
    items: {
        type: Array,
        default: null
    },
    controlsEnabled: {
        type: Boolean,
        default: false
    },
    paginationEnabled: {
        type: Boolean,
        default: true
    },
    paginationType: {
        type: String,
        default: 'label' // 'label' or 'scroll'
    },
    hasPadding: {
        type: Boolean,
        default: true
    },
    controlTransitionName: {
        type: String,
        default: 'fade'
    },
    trackIntersection: {
        type: Boolean,
        default: false
    },
    intersectionThreshold: {
        type: Number,
        default: 0.75
    }
});

const overflowRight = ref(false);
const overflowLeft = ref(false);
const itemIsVisible = ref([]);
const currentIndex = ref(0);

const scrollContainer = ref(null);
const itemsEl = ref([]);

watch(
    () => props.items,
    (value) => {
        itemIsVisible.value = value ? value.map(() => null) : [];
    },
    { immediate: true }
);

watch(
    () => props.controlsEnabled,
    (controlsEnabled) => {
        if (controlsEnabled) {
            enableControls();
        } else {
            disableControls();
        }
    },
    { immediate: true }
);

watch(
    () => props.trackIntersection,
    (trackingIntersection) => {
        if (trackingIntersection) {
            addIntersectionObservers();
        } else {
            removeIntersectionObservers();
        }
    },
    { immediate: true }
);

onMounted(async() => {
    if (props.controlsEnabled) {
        enableControls();
    }

    if (props.trackIntersection) {
        await nextTick();
        addIntersectionObservers();
    }
});

onUnmounted(() => {
    disableControls();
    removeIntersectionObservers();
});

function calculatePositions() {
    if (!itemsEl.value || !itemsEl.value.length) {
        return;
    }

    const container = scrollContainer.value?.$el || scrollContainer.value;
    if (!container) {
        return;
    }

    const cardWidth = itemsEl.value[0].offsetWidth;

    overflowLeft.value = container.scrollLeft > 0;
    overflowRight.value = container.scrollLeft + container.offsetWidth < container.scrollWidth;

    currentIndex.value = Math.round(container.scrollLeft / cardWidth);
}

function enableControls() {
    if(typeof window === 'undefined') {
        return;
    }
    calculatePositions();

    window.addEventListener('resize', calculatePositions);
    const container = scrollContainer.value?.$el || scrollContainer.value;
    container?.addEventListener('scroll', calculatePositions);
}

function disableControls() {
    if(typeof window === 'undefined') {
        return;
    }

    window.removeEventListener('resize', calculatePositions);

    const container = scrollContainer.value?.$el || scrollContainer.value;
    container?.removeEventListener('scroll', calculatePositions);
}

function scroll(direction) {
    if (!itemsEl.value || !itemsEl.value.length) {
        return;
    }

    const cardWidth = itemsEl.value[0].offsetWidth;
    const container = scrollContainer.value?.$el || scrollContainer.value;
    let scrollX = 0;

    if (!container) {
        return;
    }

    if (direction === 'left') {
        scrollX = container.scrollLeft - cardWidth;
    } else if (direction === 'right') {
        scrollX = container.scrollLeft + cardWidth;
    } else {
        return;
    }

    container.scrollTo({
        left: scrollX,
        behavior: 'smooth'
    });

    calculatePositions();
}

const scrollLeft = () => scroll('left');

const scrollRight = () => scroll('right');

let intersectionObserver = null;

function addIntersectionObservers() {
    if (!props.trackIntersection || !itemsEl.value.length) {
        return;
    }

    if (!intersectionObserver) {
        const observerOptions = {
            threshold: props.intersectionThreshold
        };

        intersectionObserver = new IntersectionObserver(onIntersect, observerOptions);
    }

    itemsEl.value.forEach((el) => {
        intersectionObserver.observe(el);
    });
}

function onIntersect(entries) {
    for (const entry of entries) {
        const index = itemsEl.value.indexOf(entry.target);
        if (index !== -1) {
            if (entry.isIntersecting) {
                itemIsVisible.value[index] = true;
                entry.target.dispatchEvent(new Event('enter'));
            } else {
                itemIsVisible.value[index] = false;
                entry.target.dispatchEvent(new Event('leave'));
            }
        }
    }
}

function removeIntersectionObservers() {
    if (intersectionObserver) {
        intersectionObserver.disconnect();
        intersectionObserver = null;
    }
}
</script>

<style lang="less" src="./BaseSliderCore.less"></style>
